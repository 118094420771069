<template>
  <div class="access-digital-card-wrapper">
    <div class="access-digital-card" @click="showModal = true">
      <TheOrganizationLogo class="access-digital-card__logo" />
      <p class="access-digital-card__text">Access your Digital Card</p>
    </div>
    <DownloadMobileAppModal v-model="showModal" />
  </div>
</template>

<script>
import TheOrganizationLogo from '@/components/logos/TheOrganizationLogo.vue';
import DownloadMobileAppModal from '@/components/modals/DownloadMobileAppModal.vue';

export default {
  name: 'AccessDigitalCard',
  components: { DownloadMobileAppModal, TheOrganizationLogo },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>
