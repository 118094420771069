<template>
  <RouterLink :to="routeTo">
    <div class="img">
      <template v-if="guideEvents.length > 1">
        <div
          v-for="event in guideEvents"
          :key="event.id"
          class="item"
          :class="{ 'item--w-100': guideEvents.length === 2 }"
        >
          <div class="pic w-100 h-100" :style="{ backgroundImage: `url(${getMedia(event.eventable)})` }" />
        </div>
      </template>

      <template v-else>
        <div class="item item--full-size">
          <div class="pic w-100 h-100" :style="{ backgroundImage: firstEventMediaBgUrl }" />
        </div>
      </template>
    </div>

    <div class="data-item__title">{{ guide.title }}</div>
  </RouterLink>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';

export default {
  name: 'ProfileMyArtGuidesItem',
  props: {
    guide: {
      type: Object,
      required: true,
    },
  },
  computed: {
    firstEventMediaBgUrl() {
      const event = this.guideEvents?.[0]?.eventable;

      return event ? `url(${this.getMedia(event)})` : 'none';
    },
    guideEvents() {
      return this.guide.events.slice(0, 4);
    },
    routeTo() {
      return getEntityRoute(this.guide);
    },
  },
  methods: {
    getMedia(event) {
      return new ImageHandler().getFirstImgFromMedia(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  $gap: 6px;

  width: 100%;
  height: 250px;
  display: flex;
  flex-wrap: wrap;
  gap: $gap;
  overflow: hidden;

  &:hover {
    .item {
      transform: scale(0.95);
    }
  }

  .pic {
    background-size: cover;
    background-position: center;
    background-color: #d9d9d9;
  }

  .item {
    display: block;
    width: calc(50% - $gap / 2);
    height: calc(50% - $gap / 2);
    background-size: cover;
    background-position: center;
    transition: all 0.3s linear;

    &--w-100 {
      width: 100%;
    }

    &--full-size {
      width: 100%;
      height: 100%;
    }
  }
}

.data-item__title {
  padding-top: 15px;
  color: #353535;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.85px;
  line-height: 34px;
  text-transform: uppercase;
}
</style>
