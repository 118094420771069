<template>
  <div class="profile-body profile-detail-tab">
    <BackHeader is-static :messages="15" title="My account" />

    <div class="profile-item">
      <div class="user">
        <div class="user__col">
          <AccessDigitalCard />
        </div>

        <div v-if="!museumRole" class="user__col d-none d-md-block d-xl-block d-lg-block">
          <div class="user-data">
            <div class="user-data__top">
              <div
                v-if="userDataLiaison.photo_url"
                class="user-data__photo bg"
                :style="{ backgroundImage: `url('${userDataLiaison.photo_url}')` }"
              />
              <div class="user-data__name" :class="{ 'pl-0': !userDataLiaison.photo_url }">
                {{ userDataLiaison.name }}
              </div>
            </div>

            <RouterLink class="btn user-data__liaison-btn" :to="{ name: 'member-liaison' }"
              >YOUR DEDICATED MEMBER LIAISON</RouterLink
            >

            <div class="user-data__info">
              <p class="wrap">{{ userDataLiaison.email }}</p>
              <p v-show="userDataLiaison.phone" class="wrap">{{ userDataLiaison.phone }}</p>
            </div>

            <div class="user-data__link">
              <RouterLink :to="{ name: 'contact-us' }">
                <span>CONTACT</span>
                <span>
                  <TheArrowHorizIcon flip="flip" />
                </span>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isUserMembershipLapsed" class="my-items">
        <MyAgenda />
        <ProfileMyArtGuides />
        <MyFavorites />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import meQuery from '@/graphql/me/Me.query.gql';

import BackHeader from '@/components/partials/BackHeader';
import MyAgenda from '@/components/partials/MyAgenda';
import ProfileMyArtGuides from '@/components/partials/ProfileMyArtGuides';
import MyFavorites from '@/components/partials/MyFavorites';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';
import TheOrganizationLogo from '@/components/logos/TheOrganizationLogo.vue';
import AccessDigitalCard from '@/components/partials/AccessDigitalCard.vue';

export default {
  name: 'ProfileDetails',
  components: {
    AccessDigitalCard,
    TheOrganizationLogo,
    TheArrowHorizIcon,
    BackHeader,
    MyAgenda,
    ProfileMyArtGuides,
    MyFavorites,
  },
  metaInfo: {
    title: 'Profile',
  },

  apollo: {
    me: {
      query: meQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      result({ data }) {
        if (data.me) {
          this.userCard = data.me;
          this.userDataLiaison = data.me.liaison;

          if (data.me.membership) {
            this.$store.dispatch('setUserMembership', { ...data.me.membership });
          }
        }
      },
    },
  },

  data() {
    return {
      userCard: null,
      userDataLiaison: {},
    };
  },
  computed: {
    ...mapState(['museumRole']),
    ...mapGetters(['isUserMembershipLapsed']),
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  word-break: break-word;
}

.see-all {
  color: #363636;
  font-size: 18px;
  font-weight: 300;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    text-decoration: none;
  }
}

.data-item-wrap {
  padding: 0 !important;
}
</style>
