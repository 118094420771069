<template>
  <component class="my-favorites-item" :is="routeTo ? 'RouterLink' : 'div'" :to="routeTo">
    <div class="data-item__img" :style="`background-image: url(${media})`" />
    <div class="data-item__title">{{ item.title }}</div>
    <div v-if="item.address" class="small-grey">{{ item.address }}</div>

    <div v-if="destination" class="d-flex">
      <div class="small-grey">{{ destination.title }},</div>
      <div v-if="destination.country" class="small-grey ml-1">{{ destination.country }}</div>
    </div>

    <div v-if="startDate" class="d-flex">
      <div v-if="startDate" class="small-grey">{{ startDate }}</div>
      <div v-if="endDate && startDate !== endDate" class="small-grey ml-1">- {{ endDate }}</div>
    </div>

    <div v-if="startPublishingAt" class="small-grey">{{ startPublishingAt }}</div>
    <div class="small-grey mt-2">{{ item.typeForHuman }}</div>
  </component>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';
import { formatDate } from '@/helpers/dateTimeHelper';

export default {
  name: 'MyFavoritesItem',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    routeTo() {
      return getEntityRoute(this.item);
    },
    media() {
      return new ImageHandler().getFirstImgFromMedia(this.item);
    },
    destination() {
      return this.item.insiderTip ? this.item.insiderTip.destination : this.item.destination;
    },
    startDate() {
      return formatDate(this.item.start_date || this.item.earliestStartDate);
    },
    endDate() {
      return formatDate(this.item.end_date || this.item.latestEndDate);
    },
    startPublishingAt() {
      return formatDate(this.item.startPublishingAt);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-favorites-item {
  .data-item__img {
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.2s linear;
  }

  &:hover {
    .data-item__img {
      transform: scale(0.95);
    }
  }
}
.data-item__title {
  margin-bottom: 5px;
  color: #353535;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.85px;
  line-height: 34px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
