<template>
  <div class="page-wrap" v-if="favoriteData && favoriteData.length">
    <div class="main-content profile-data-page" :style="{ paddingLeft: !showAsSeparatePage ? '0%' : '1.5%' }">
      <BackHeader is-static is-show-title title="My Favourites" />

      <div class="container">
        <div class="row d-flex flex-column">
          <div
            v-if="favoriteData.length"
            class="data-page-top d-flex justify-content-between align-items-center"
            data-aos="fade-up"
          >
            <h2 :class="!showAsSeparatePage ? 'page-title' : 'current-page-title'">MY FAVOURITES</h2>
            <RouterLink v-if="!showAsSeparatePage" class="see-all" :to="{ name: 'all-favorites' }">See all</RouterLink>
          </div>

          <div class="my-items">
            <div class="item">
              <div class="d-flex justify-content-center">
                <AppLoader v-if="isLoading" />
              </div>
              <div class="row">
                <div
                  v-for="item in favoriteData"
                  :key="`fav-${item.__typename}-${item.id}`"
                  class="col-lg-6 col-xl-4 pad-bottom"
                  data-aos="fade-up"
                >
                  <MyFavoritesItem :item="item" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import meFavoritesQuery from '@/graphql/me/favorite/MeFavorites.query.gql';

import BackHeader from '@/components/partials/BackHeader';
import MyFavoritesItem from '@/components/partials/MyFavoritesItem';

export default {
  name: 'MyFavorites',
  components: {
    BackHeader,
    MyFavoritesItem,
  },
  props: {
    showAsSeparatePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      favoriteData: [],
      isLoading: true,
    };
  },
  apollo: {
    me: {
      query: meFavoritesQuery,
      fetchPolicy: 'no-cache',
      result(result) {
        if (result.data.me.favorites) {
          this.isLoading = false;

          const favorites = result.data.me.favorites.filter((f) => f.id);
          this.favoriteData = this.showAsSeparatePage ? favorites : favorites.slice(0, 3);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-data-page {
  padding-top: 60px;
}

.data-page-top {
  margin-top: 5%;
  margin-bottom: 0;
  padding-bottom: 30px;
}

.page-title {
  margin-bottom: 0;
  color: #353535;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2.86px;
}

.current-page-title {
  color: #363636;
  font-size: 50px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 13.33px;
  align-items: center;
  margin-bottom: 55px;
  margin-top: 12px;
}

.see-all {
  color: #363636;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1.11px;
  text-decoration: underline;
  text-transform: uppercase;
}

.pad-bottom {
  padding-bottom: 40px;
}
</style>
