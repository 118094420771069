<template>
  <Transition name="fade">
    <div v-if="value" class="app-modal-wrapper" @click.self="handleClose">
      <div class="app-modal">
        <div class="app-modal-body">
          <header>
            <button class="app-modal-close" type="button" @click="handleClose"></button>
            <h3 class="app-modal-title">Download our mobile app?</h3>
          </header>
          <p>
            Please download the app to access your digital card or contact us to help:<br />
            <a href="tel:+1 212 257 0202">+1 212 257 0202</a> for Americas<br /><a href="tel:+44 (0) 203 384 0055"
              >+44 (0) 203 384 0055</a
            >
            for Europe.
          </p>
        </div>
        <div class="app-modal-actions">
          <a class="action active" :href="downloadLink">Download</a>
          <button class="action" type="button" @click="handleClose">Skip</button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapState } from 'vuex';
import downloadMobileAppLink from '@/helpers/downloadMobileAppLink';

export default {
  name: 'DownloadMobileAppModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['isAppleDevice']),
    downloadLink() {
      return downloadMobileAppLink(this.isAppleDevice);
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-modal {
  &-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    height: 100vh;
    left: 0;
    z-index: 999999999;
    background-color: rgba(#000, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 800px;
  height: calc(100% - 32px);
  margin: 16px;
  padding: 18px 24px 24px;
  text-align: left;
  background-color: #fff;
  overflow-x: hidden;

  @media (min-width: 768px) {
    height: auto;
  }

  &-body {
    p,
    a {
      color: #5b5d62;
    }
  }

  &-close {
    display: block;
    width: 1em;
    height: 1em;
    margin-left: auto;
    color: #000;
    opacity: 0.4;
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
      center/1em auto no-repeat;

    &:hover {
      opacity: 0.75;
    }
  }

  &-title {
    margin-top: 12px;
    margin-bottom: 24px;
    font-family: 'Futura LT Pro Book', sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 6px;
    text-transform: uppercase;
  }

  &-actions {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-around;
    }

    .action {
      display: block;
      min-width: 180px;
      margin-top: 16px;
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: #5b5d62;
      border: 1px solid #5b5d62;
      border-radius: 1px;
      background-color: #fff;

      &.active {
        color: #fff;
        background-color: #202020;

        &:hover,
        &:focus {
          color: #fff !important;
          background-color: lighten(#202020, 20%);
        }
      }
    }
  }
}
</style>
